:root{
  --blue: rgb(3, 99, 248);
  --blue-dark: rgb(3, 38, 94);
  --sky: rgb(113, 238, 255);
  --sky-light: rgb(215, 242, 253);
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  input,
  textarea {
    @apply bg-gray-100 border border-gray-300
  }

  input:active,
  textarea:active{
    @apply border-none;
  }
  
  textarea{
    height: 200px;
  }

  button{
    @apply py-2 px-8 bg-gray-500 text-white;
  }

  button.default{
    @apply bg-gray-500 text-white;
  }

  button.clear{
    @apply border border-white;
    background-color: rgba(0, 0, 0, 0.3);
  }
}
